import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { DeviceAuth } from "./DeviceAuth";
import { DeviceAuthUpdate } from "./DeviceAuthUpdate";
import { HandheldPermissionMain } from "./HandheldPermissionMain";
import { POSMessageMain } from "./POSMessageMain";
import { POSStatusMain } from "./POSStatusMain";
import { StoreListMain } from "./StoreListMain";
import { EquipmentListMain } from "./EquipmentListMain";
import { GiftCardLookup } from "./GiftCardLookup";

export const ITBody = () => {
  const [page, setPage] = useState("menu");
  const [currentDevice, setCurrentDevice] = useState({
    DeviceID: "",
    DeviceAuthCode: "",
    DisplayName: "",
    StoreNo: "",
    Site: "",
    Status: "",
    DeviceType: "",
  });

  const [MenuResponse, setMenuResponse] = useState("");

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
    />
  );

  const deviceAuthPage = (
    <DeviceAuth
      page={page}
      setPage={setPage}
      setCurrentDevice={setCurrentDevice}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const createPage = (
    <DeviceAuthUpdate
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      currentDevice={currentDevice}
      Origin="CREATE"
    />
  );

  const editPage = (
    <DeviceAuthUpdate
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      currentDevice={currentDevice}
      Origin="EDIT"
    />
  );

  const handheldPermissionPage = (
    <HandheldPermissionMain
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const posMessagePage = (
    <POSMessageMain
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const posStatusPage = (
    <POSStatusMain
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const storeListPage = (
    <StoreListMain
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const equipmentListPage = (
    <EquipmentListMain
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const giftCardLookupPage = (
    <GiftCardLookup
      page={page}
      setPage={setPage}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "deviceauth-main":
        return deviceAuthPage;
      case "deviceauth-create":
        return createPage;
      case "deviceauth-edit":
        return editPage;
      case "handheldpermission-main":
        return handheldPermissionPage;
      case "posmessage-main":
        return posMessagePage;
      case "posstatus-main":
        return posStatusPage;
      case "storelist-main":
        return storeListPage;
      case "equipmentlist-main":
        return equipmentListPage;
      case "giftcardlookup":
        return giftCardLookupPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
