import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const Menu = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  const [isStockTakeSelected, setIsStockTakeSelected] = useState(
    props.CurrentStockTake === "" ? false : true
  );
  const [ddlStockTake, setDDLStockTake] = useState([]);

  const refStockTake = useRef();

  const getDDL = async () => {
    const resObj = await runFetch(`${spURL}Stocktake_List_StockTake_ALL`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLStockTake(spResponse.Table);
      }
    }
  };

  const handleClick_Select = () => {
    setMenuResponse("");

    if (isStockTakeSelected) {
      setIsStockTakeSelected((values) => !values);
      props.setCurrentStockTake("");
      props.setIsCurrentStockTakeStarted({
        Started: false,
        Date: "",
      });
    } else {
      if (refStockTake.current.value !== "") {
        props.setCurrentStockTake(refStockTake.current.value);

        const _filteredstocktake = ddlStockTake.filter(
          (stocktake) => stocktake.Description === refStockTake.current.value
        )[0];
        const _isstocktakestarted = _filteredstocktake.IsStockTakeStarted;
        const _stocktakestartdate = _filteredstocktake.StartDate;

        props.setIsCurrentStockTakeStarted({
          Started: _isstocktakestarted === 1,
          Date: _stocktakestartdate,
        });

        setIsStockTakeSelected((values) => !values);
      } else {
        setMenuResponse("ERROR!: No StockTake selected.");
      }
    }
  };

  useEffect(() => {
    getDDL();
  }, []);

  useEffect(() => {}, [props.CurrentStockTake]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {isStockTakeSelected ? (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
        ) : (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <select
              name="stocktake"
              id="stocktake-scan-stocktake"
              ref={refStockTake}
            >
              <option value=""></option>
              {ddlStockTake.map((item, index) => (
                <option
                  key={index}
                  value={item.Description}
                >
                  {item.Description}
                </option>
              ))}
            </select>
          </div>
        )}
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Select}
        >
          {isStockTakeSelected ? "Cancel" : "Select"}
        </button>
        <br />
        <br />
        {isStockTakeSelected ? (
          <>
            <div className="home-table">
              {props.IsCurrentStockTakeStarted.Started ? (
                <MenuButton
                  onClick={() => props.setPage("scan")}
                  button="S"
                  colour={red}
                  title="Scan"
                />
              ) : (
                <>
                  <p className="error-message">
                    This stocktake is not available for scans until{" "}
                    {ConvertDateFormat(props.IsCurrentStockTakeStarted.Date, "en-CA")}
                  </p>
                </>
              )}
              {props.Access.StockTakeManage && (
                <MenuButton
                  onClick={() => props.setPage("check")}
                  button="C"
                  colour={red}
                  title="Check"
                />
              )}
              {props.Access.StockTakeManage && (
                <MenuButton
                  onClick={() => props.setPage("manage")}
                  button="M"
                  colour={red}
                  title="Manage"
                />
              )}
            </div>
            <div className="home-table">
              {props.Access.StockTakeManage && (
                <MenuButton
                  onClick={() => props.setPage("reports")}
                  button="R"
                  colour={red}
                  title="Reports"
                />
              )}
              {props.Access.StockTakeAdmin && (
                <MenuButton
                  onClick={() => props.setPage("admin-menu")}
                  button="A"
                  colour={red}
                  title="Admin"
                />
              )}
              {props.Access.StockTakeManage && (
                <MenuButton
                  onClick={() => props.setPage("bays")}
                  button="B"
                  colour={red}
                  title="Bays"
                />
              )}
              {props.Access.StockTakeManage && (
                <MenuButton
                  onClick={() => props.setPage("admin-staffbarcode")}
                  button="SB"
                  colour={red}
                  title="Staff Barcode"
                />
              )}
            </div>
          </>
        ) : (
          <div className="home-table">
            {props.Access.StockTakeManage && (
              <MenuButton
                onClick={() => props.setPage("create")}
                button="C"
                colour={red}
                title="Create"
              />
            )}
            {props.Access.StockTakeAdmin && (
              <MenuButton
                onClick={() => props.setPage("admin-menu")}
                button="A"
                colour={red}
                title="Admin"
              />
            )}
            {props.Access.StockTakeManage && (
              <MenuButton
                onClick={() => props.setPage("staffbarcode")}
                button="SB"
                colour={red}
                title="Staff Barcode"
              />
            )}
          </div>
        )}

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={red}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
