import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Reports } from "./Reports";
import { Create } from "./Create";
import { Check } from "./Check";
import { Manage } from "./Manage";
import { Scan } from "./Scan";
import { AdminMenu } from "./AdminMenu";
import { AdminFinalise } from "./AdminFinalise";
import { StaffBarcode } from "./StaffBarcode";
import { AdminBlockSubCat } from "./AdminBlockSubCat";
import store from "../../store";
import { getAccessData } from "../../App";
import { Bays } from "./Bays";

export const StockTakeBody = () => {
  const [userid, ,] = store.useState("userid");
  const [page, setPage] = useState("menu");

  const [StockTakeBay, setStockTakeBay] = useState("");
  const [From, setFrom] = useState("");

  const [CurrentStockTake, setCurrentStockTake] = useState("");
  const [IsCurrentStockTakeStarted, setIsCurrentStockTakeStarted] = useState(false);

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, []);

  const menuPage = (
    <Menu
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
      IsCurrentStockTakeStarted={IsCurrentStockTakeStarted}
      setIsCurrentStockTakeStarted={setIsCurrentStockTakeStarted}
      Access={Access}
    />
  );

  const scanPage = (
    <Scan
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const checkPage = (
    <Check
      setPage={setPage}
      setStockTakeBay={setStockTakeBay}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const createPage = <Create setPage={setPage} />;

  const managePage = (
    <Manage
      setPage={setPage}
      StockTakeBay={StockTakeBay}
      setStockTakeBay={setStockTakeBay}
      From={From}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const reportsPage = (
    <Reports
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const adminMenuPage = <AdminMenu setPage={setPage} />;

  const adminFinaliseStockTakePage = <AdminFinalise setPage={setPage} />;

  const staffBarcodePage = <StaffBarcode setPage={setPage} />;

  const adminBlockSubCatPage = <AdminBlockSubCat setPage={setPage} />;

  const baysPage = (
    <Bays
      page={page}
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "scan":
        return scanPage;
      case "check":
        return checkPage;
      case "create":
        return createPage;
      case "manage":
        return managePage;
      case "reports":
        return reportsPage;
      case "admin-menu":
        return adminMenuPage;
      case "admin-finalise":
        return adminFinaliseStockTakePage;
      case "staffbarcode":
        return staffBarcodePage;
      case "admin-blocksubcat":
        return adminBlockSubCatPage;
      case "bays":
        return baysPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
