import React, { useState, useEffect } from "react";
import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { CapitaliseString } from "../../functions/CapitaliseString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupConfirmBack } from "./PopupConfirmBack";
import { PopupTaskImage } from "./PopupTaskImage";
import { BlobServiceClient } from "@azure/storage-blob";
import { FileUploader } from "react-drag-drop-files";
import { trackPromise } from "react-promise-tracker";
import imageCompression from "browser-image-compression";
import { PopupCameraScreen } from "../StoreAuditTool/PopupCameraScreen";

export const AdminTaskMobile = (props) => {
  const [StoreComplianceToolResponse, setStoreComplianceToolResponse] = useState("");

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstorecompliancetoolimages"); // create a containerClient

  const [StoreList, setStoreList] = useState([]);

  const [SearchStoreName, setSearchStoreName] = useState("");
  const [IsAssignedToAllStores, setIsAssignedToAllStores] = useState(true);
  const [IsImageRequired, setIsImageRequired] = useState(false);
  const [IsImageModified, setIsImageModified] = useState(false);

  const emptyTaskDetails = {
    TaskTitle: "",
    TaskDescription: "",
    TaskDueDate: "",
    IsImageRequired: "",
    SampleImageURL: "",
  };
  const [OriginalTaskDetails, setOriginalTaskDetails] = useState(emptyTaskDetails);
  const [OriginalStoreList, setOriginalStoreList] = useState([]);

  const emptySampleImageFile = {
    name: "",
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptySampleImageFile);

  const [SelectedStore, setSelectedStore] = useState({
    StoreNo: "",
    StoreName: "",
  });

  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const refTaskTitle = useRef();
  const refTaskDescription = useRef();
  const refTaskDueDate = useRef();
  const refIsImageRequired = useRef();
  const refAssignToAllStores = useRef();

  const submitTask = async () => {
    const blobURL = IsImageRequired
      ? `https://tmportalimages.blob.core.windows.net/handheldstorecompliancetoolimages/${encodeURIComponent(
          UploadedImageFile.name
        )}`
      : "";

    const resObj = await runFetch(
      `${spURL}Handheld_StoreComplianceToolAdmin_Task_${CapitaliseString(props.PageType)}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            TaskID: props.SelectedTaskID,
            TaskTitle: refTaskTitle.current.value,
            TaskDescription: refTaskDescription.current.value,
            TaskDueDate: refTaskDueDate.current.value,
            IsImageRequired: refIsImageRequired.current.checked ? 1 : 0,
            SampleImageURL: IsImageModified ? blobURL : OriginalTaskDetails.SampleImageURL,
            AssignedToStores: JSON.stringify(
              StoreList.map((store) => ({
                ...store,
                IsSelected: store.IsSelected ? 1 : 0,
              }))
            ),
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setStoreComplianceToolResponse(spResponse.Table[0].OutputString);
          props.setPage("admin-tasklist");
        }
      }
    }
  };

  const handleClick_Create = async () => {
    if (IsImageRequired) {
      if (UploadedImageFile.blob === "" && IsImageModified) {
        setStoreComplianceToolResponse("ERROR!: Image not uploaded.");
      } else {
        if (IsImageModified) {
          const blobClient = containerClient.getBlockBlobClient(UploadedImageFile.name);

          trackPromise(blobClient.uploadData(UploadedImageFile.blob))
            .then(async () => {
              await submitTask();
            })
            .catch((err) => {
              setStoreComplianceToolResponse(`ERROR!: Image upload error. ${err}`);
            });
        } else {
          await submitTask();
        }
      }
    } else {
      await submitTask();
    }
  };

  const handleClick_AssignedToAllStores = (e) => {
    setIsAssignedToAllStores(e.target.checked);
    setStoreList(
      StoreList.map((store) => ({
        ...store,
        IsSelected: e.target.checked,
      }))
    );
  };

  const handleClick_IsImageRequired = (e) => {
    setIsImageRequired(e.target.checked);
  };

  const getStoreList = async () => {
    const resObj = await runFetch(
      `${spURL_NoInputJSON}Handheld_StoreComplianceTool_StoreList_Get`,
      {
        method: "POST",
      }
    );

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setStoreList(
          spResponse.Table.map((item) => ({
            ...item,
            IsSelected: true,
          }))
        );
      }
    }
  };

  const loadTaskDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreComplianceToolAdmin_Task_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TaskID: props.SelectedTaskID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreComplianceToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setOriginalTaskDetails(spResponse.Table[0]);

        refTaskTitle.current.value = spResponse.Table[0].TaskTitle;
        refTaskDescription.current.value = spResponse.Table[0].TaskDescription;
        refTaskDueDate.current.value = ConvertDateFormat(spResponse.Table[0].TaskDueDate, "en-CA");
        refIsImageRequired.current.checked = spResponse.Table[0].IsImageRequired === 1;

        setIsImageRequired(spResponse.Table[0].IsImageRequired === 1);
        setIsImageModified(false);
      }

      if (spResponse.Table1 !== undefined) {
        setOriginalStoreList(spResponse.Table1);
        setStoreList(spResponse.Table1);

        refAssignToAllStores.current.checked =
          spResponse.Table1.length ===
          spResponse.Table1.filter((store) => store.IsSelected === 1).length;

        setIsAssignedToAllStores(
          spResponse.Table1.length ===
            spResponse.Table1.filter((store) => store.IsSelected === 1).length
        );
      }
    }
  };

  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          setIsImageModified(true);
          setUploadedImageFile({ blob: imgBlob, name: `${props.SelectedTaskID}_sample.jpg` });
        })
    );
  };

  const compressFile = (file) => {
    return new Promise(async function (resolve, reject) {
      let compressedFile = await imageCompression(file, {
        maxSizeMB: 2, // max image size = 200kb
        maxWidthOrHeight: 300,
        useWebWorker: true,
      });

      // let compressedFile = file;
      resolve(compressedFile);
    });
  };

  useEffect(() => {
    if (props.PageType === "CREATE") {
      getStoreList();
    }

    if (["EDIT", "VIEW"].includes(props.PageType)) {
      loadTaskDetails();
    }
  }, [props.From]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>{props.PageType} Task</strong>
        </h4>
        <p className="error-message">{StoreComplianceToolResponse}</p>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Title:</strong>
          </p>
          <input
            type="text"
            ref={refTaskTitle}
            className={`${props.PageType === "VIEW" && "disabled"}`}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            ref={refTaskDescription}
            rows="10"
            placeholder="Enter task description here..."
            className={`${props.PageType === "VIEW" && "disabled"}`}
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Due Date:</strong>
          </p>
          <input
            type="date"
            ref={refTaskDueDate}
            className={`${props.PageType === "VIEW" && "disabled"}`}
          />
        </div>
        <div className="weborders-printweblabels-container">
          <input
            type="checkbox"
            id="image-required"
            name="image-required"
            ref={refIsImageRequired}
            onChange={handleClick_IsImageRequired}
            className={`btnHover ${props.PageType === "VIEW" && "disabled"}`}
          />
          <label
            htmlFor="image-required"
            className={`btnHover no-highlight ${props.PageType === "VIEW" && "disabled"}`}
          >
            Is image required?
          </label>
        </div>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {IsImageRequired &&
              (props.PageType === "VIEW" ? (
                <img
                  className="storeaudittool-image"
                  src={OriginalTaskDetails.SampleImageURL}
                />
              ) : IsImageModified ? (
                UploadedImageFile.blob === "" ? (
                  <MenuButton
                    className="no-highlight"
                    button="UP"
                    colour={blue}
                    title="Upload Photo"
                    onClick={() => setShowCamera(true)}
                  />
                ) : (
                  <>
                    <img
                      className="storeaudittool-image"
                      src={URL.createObjectURL(UploadedImageFile.blob)}
                      onClick={() => setShowCamera(true)}
                    />
                    <FontAwesomeIcon
                      className="storeaudittool-deleteimage-icon btnHover"
                      icon="fas fa-times"
                      onClick={() => {
                        setIsImageModified(true);
                        setUploadedImageFile(emptySampleImageFile);
                      }}
                    />
                  </>
                )
              ) : OriginalTaskDetails.SampleImageURL === "" ? (
                <MenuButton
                  className="no-highlight"
                  button="UP"
                  colour={blue}
                  title="Upload Photo"
                  onClick={() => setShowCamera(true)}
                />
              ) : (
                <>
                  <img
                    className="storeaudittool-image"
                    src={OriginalTaskDetails.SampleImageURL}
                    onClick={() => setShowCamera(true)}
                  />
                  <FontAwesomeIcon
                    className="storeaudittool-deleteimage-icon btnHover"
                    icon="fas fa-times"
                    onClick={() => {
                      setIsImageModified(true);
                      setUploadedImageFile(emptySampleImageFile);
                    }}
                  />
                </>
              ))}
          </div>
        </div>
        <div>
          <p>
            <strong>Assign to</strong>
          </p>
          <div
            className={`padding-5 weborders-printweblabels-container ${
              props.PageType === "VIEW" && "disabled"
            }`}
          >
            <input
              type="checkbox"
              defaultChecked
              id="assign-all-stores"
              name="assign-all-stores"
              ref={refAssignToAllStores}
              onChange={handleClick_AssignedToAllStores}
              className="btnHover"
            />
            <label
              htmlFor="assign-all-stores"
              className={`btnHover no-highlight ${props.PageType === "VIEW" && "disabled"}`}
            >
              All Stores
            </label>
          </div>
          <div className={`background-lightgrey padding-5 border-radius-5`}>
            <input
              type="text"
              placeholder="Filter on stores..."
              onChange={(e) => handleChange_Input(e, setSearchStoreName)}
              className="width-full padding-2"
            />
            <ul className={`storecompliancetool-store-checkbox-container`}>
              {StoreList.filter((store) =>
                store.StoreName.toUpperCase().includes(SearchStoreName.toUpperCase())
              ).map((store, index) => (
                <li
                  key={index}
                  className="weborders-printweblabels-container no-highlight btnHover"
                  onClick={() => {
                    if (store.IsSubmitted === 1) {
                      setStoreComplianceToolResponse("");
                      setSelectedStore({
                        StoreNo: store.StoreNo,
                        StoreName: store.StoreName,
                      });
                      setShowTaskImage(true);
                    }
                  }}
                >
                  <label
                    htmlFor={`check-${store.StoreNo}`}
                    className={`btnHover ${
                      (props.PageType === "VIEW" || IsAssignedToAllStores) && "disabled"
                    }`}
                  >
                    {store.StoreName}
                    {props.PageType === "VIEW" && (
                      <span className="margin-side-5">
                        {store.IsSubmitted === 1 ? (
                          <>
                            <FontAwesomeIcon
                              className="color-green"
                              icon={solid("check")}
                            />
                            <span className="margin-side-5">
                              {ConvertDateFormat(store.DateSubmitted, "en-CA")}
                            </span>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              className="color-red"
                              icon={solid("xmark")}
                            />
                          </>
                        )}
                      </span>
                    )}
                  </label>
                  <input
                    id={`check-${store.StoreNo}`}
                    name={`check-${store.StoreNo}`}
                    type="checkbox"
                    checked={store.IsSelected}
                    className={` ${
                      (props.PageType === "VIEW" || IsAssignedToAllStores) && "disabled"
                    }`}
                    onChange={(e) => {
                      setStoreList(
                        StoreList.map((store_new) => {
                          if (
                            store_new.StoreName.toUpperCase().includes(
                              store.StoreName.toUpperCase()
                            )
                          ) {
                            return {
                              ...store_new,
                              IsSelected: e.target.checked,
                            };
                          } else {
                            return store_new;
                          }
                        })
                      );
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <PopupConfirmBack
          show={showConfirmBack}
          setShow={setShowConfirmBack}
          setPage={props.setPage}
        />

        <PopupTaskImage
          show={showTaskImage}
          setShow={setShowTaskImage}
          SelectedTaskID={props.SelectedTaskID}
          SelectedStore={SelectedStore}
        />

        <PopupCameraScreen
          show={showCamera}
          setShow={setShowCamera}
          processSnappedImage={processSnappedImage}
        />
      </div>
      <br />
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => {
            props.setStoreComplianceToolResponse("");

            if (
              OriginalTaskDetails.TaskTitle === refTaskTitle.current.value &&
              OriginalTaskDetails.TaskDescription === refTaskDescription.current.value &&
              OriginalTaskDetails.TaskDueDate === `${refTaskDueDate.current.value}T00:00:00` &&
              OriginalTaskDetails.IsImageRequired ===
                (refIsImageRequired.current.checked ? 1 : 0) &&
              OriginalStoreList === StoreList
            ) {
              props.setPage("admin-tasklist");
            } else {
              setShowConfirmBack(true);
            }
          }}
        />
        <FooterButton
          button="Home"
          colour={blue}
          onClick={props.handleClick_Home}
        />
        {["CREATE", "EDIT"].includes(props.PageType) && (
          <FooterButton
            button="Ok"
            colour={blue}
            onClick={handleClick_Create}
          />
        )}
      </div>
    </div>
  );
};
