import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { blue, purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupTransactionReceipt } from "./PopupTransactionReceipt";

export const GiftCardLookup = (props) => {
  const [PageResponse, setPageResponse] = useState("");

  const emptyGiftCardDetailsHeader = {
    RemainingAmount: 0,
    ExpiringDate: "",
    Status: "",
  };
  const [GiftCardDetailsHeader, setGiftCardDetailsHeader] = useState(emptyGiftCardDetailsHeader);
  const [GiftCardDetailsLine, setGiftCardDetailsLine] = useState([]);
  const [PinCode, setPinCode] = useState("");

  const [SelectedReceiptNo, setSelectedReceiptNo] = useState("");

  const [showTransactionReceipt, setShowTransactionReceipt] = useState(false);

  const refGiftCardNo = useRef();

  const handleClick_GetPin = async () => {
    setPageResponse("");
    const resObj = await runFetch(`${spURL}Handheld_GiftCardLookup_GiftCardDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          CardNo: refGiftCardNo.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setGiftCardDetailsHeader({
          RemainingAmount: spResponse.Table[0]["Remaining Amount"],
          ExpiringDate: spResponse.Table[0]["Expiring Date"],
          Status: spResponse.Table[0].Status,
        });
      } else {
        setGiftCardDetailsHeader(emptyGiftCardDetailsHeader);
      }

      if (spResponse.Table1 !== undefined) {
        setGiftCardDetailsLine(
          spResponse.Table1.map((line) => ({
            Date: line.Date,
            StoreName: line.StoreName,
            ReceiptNo: line["Receipt Number"],
            TransactionTotal: line.TransactionTotal,
            AmountUsed: line.AmountUsed,
            RunningBalance: line.RunningBalance,
          }))
        );
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setPinCode(spResponse.Table2[0].PinCode || "");
      } else {
        setPinCode("");
      }
    }

    refGiftCardNo.current.select();
  };

  const handleClick_Row = (_receiptno) => {
    setPageResponse("");
    setSelectedReceiptNo(_receiptno);
    setShowTransactionReceipt(true);
  };

  useEffect(() => {
    let ignore = false;

    refGiftCardNo.current.select();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <div>
          <h1>
            <strong>Gift Card Pin Code</strong>
          </h1>
          <div className="storetransfer-input-row">
            <p>
              <strong>Gift Card No:</strong>
            </p>
            <input
              type="text"
              ref={refGiftCardNo}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_GetPin)}
            />
          </div>
          {PinCode !== "" && (
            <>
              <div className="storetransfer-input-row">
                <p>
                  <strong>Pin:</strong>
                </p>
                <p>{PinCode}</p>
              </div>
              <div className="storetransfer-input-row">
                <p>
                  <strong>Status:</strong>
                </p>
                <p
                  className={`color-${
                    GiftCardDetailsHeader.Status === "Active" ? "lightgreen" : "red"
                  }`}
                >
                  {GiftCardDetailsHeader.Status}
                </p>
              </div>
              <div className="storetransfer-input-row">
                <p>
                  <strong>Remaining Amount:</strong>
                </p>
                <p>{convertToCurrency(GiftCardDetailsHeader.RemainingAmount, "$")}</p>
              </div>
              <div className="storetransfer-input-row">
                <p>
                  <strong>Expiry Date</strong>
                </p>
                <p>{ConvertDateFormat(GiftCardDetailsHeader.ExpiringDate, "en-CA")}</p>
              </div>
              <br />
              <table className="itemdetails-table">
                <thead>
                  <th>Date</th>
                  <th>Store</th>
                  <th>ReceiptNo</th>
                  <th>TransactionTotal</th>
                  <th>Amount Used</th>
                  <th>Running Balance</th>
                </thead>
                <tbody>
                  {GiftCardDetailsLine.map((line, index) => (
                    <tr
                      key={index}
                      className="btnHover"
                      onClick={() => handleClick_Row(line.ReceiptNo)}
                    >
                      <td>{ConvertDateFormat(line.Date, "en-CA")}</td>
                      <td>{line.StoreName}</td>
                      <td>{line.ReceiptNo}</td>
                      <td>{convertToCurrency(line.TransactionTotal, "$")}</td>
                      <td>{convertToCurrency(line.AmountUsed, "$")}</td>
                      <td>{convertToCurrency(line.RunningBalance, "$")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <br />
        <p className="error-message">{PageResponse}</p>

        <PopupTransactionReceipt
          show={showTransactionReceipt}
          setShow={setShowTransactionReceipt}
          SelectedReceiptNo={SelectedReceiptNo}
          setSelectedReceiptNo={setSelectedReceiptNo}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        <FooterButton
          button="Pin"
          colour={blue}
          onClick={handleClick_GetPin}
        />
      </div>
    </div>
  );
};
