import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupAddSubCat } from "./PopupAddSubCat";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { isInputInRange } from "../../functions/IsInputInRange";

export const Bays = (props) => {
  const [storeno, ,] = store.useState("storeno");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [BaysTable, setBaysTable] = useState([]);

  const [FilterStartBayNo, setFilterStartBayNo] = useState("");
  const [FilterEndBayNo, setFilterEndBayNo] = useState("");
  const [FilterIncludeBays, setFilterIncludeBays] = useState("");
  const [FilterStatus, setFilterStatus] = useState("");
  const [FilterCheck, setFilterCheck] = useState("");

  const getBaysTable = async () => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_BayList_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StockTake: props.CurrentStockTake,
          Store: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setBaysTable(
          spResponse.Table.map((bay) => ({
            BayNo: bay.BayNo,
            ItemQuantity: bay.ItemQuantity,
            Counted: bay.Counted,
            Recounted: bay.Recounted,
            LastCountTime: bay.LastCountTime,
            Status: bay.Status,
            Check: bay.Check,
            CountedStaff: bay.CountedStaff,
            RecountStaff: bay.RecountStaff,
          }))
        );
      }
    }
  };

  const handleClick_Back = () => {
    setStockTakeResponse("");
    props.setPage("menu");
  };

  const handleClick_FilterIncludeBays = (e) => {
    setStockTakeResponse("");
    setFilterIncludeBays(e.target.value);
  };

  useEffect(() => {
    getBaysTable();
  }, []);

  return (
    <div className="stocktake-admin-blocksubcat-page-container">
      <div className="stocktake-admin-blocksubcat-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>BayNo:</strong>
          </p>
          <div className="from-to-text-container">
            <input
              type="text"
              onChange={(e) => {
                setStockTakeResponse("");
                setFilterStartBayNo(e.target.value);
              }}
            />
            -
            <input
              type="text"
              onChange={(e) => {
                setStockTakeResponse("");
                setFilterEndBayNo(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Include bays:</strong>
          </p>
          <input
            type="radio"
            id="includebays-all"
            name="includebays"
            onClick={handleClick_FilterIncludeBays}
            value=""
            defaultChecked
          />
          <label htmlFor="includebays-all">All</label>
          <input
            type="radio"
            id="includebays-counted"
            name="includebays"
            onClick={handleClick_FilterIncludeBays}
            value="COUNTED"
          />
          <label htmlFor="includebays-counted">Counted</label>
          <input
            type="radio"
            id="includebays-notcounted"
            name="includebays"
            onClick={handleClick_FilterIncludeBays}
            value="NOTCOUNTED"
          />
          <label htmlFor="includebays-notcounted">Not Counted</label>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Status:</strong>
          </p>
          <select
            onChange={(e) => {
              setStockTakeResponse("");
              setFilterStatus(e.target.value);
            }}
          >
            <option value=""></option>
            {BaysTable.map((bay) => bay.Status)
              .filter((value, index, self) => self.indexOf(value) === index && value !== "")
              .map((val) => (
                <option value={val}>{val}</option>
              ))}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Check:</strong>
          </p>
          <select
            onChange={(e) => {
              setStockTakeResponse("");
              setFilterCheck(e.target.value);
            }}
          >
            <option value=""></option>
            {BaysTable.map((bay) => bay.Check)
              .filter((value, index, self) => self.indexOf(value) === index && value !== "")
              .map((val) => (
                <option value={val}>{val}</option>
              ))}
          </select>
        </div>
      </div>
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p className="error-message">{StockTakeResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>BayNo</th>
              <th>Items</th>
              <th>Counted</th>
              <th>Counted Staff</th>
              <th>Recounted</th>
              <th>Recounted Staff</th>
              <th>LastCountTime</th>
              <th>Status</th>
              <th>Check</th>
            </tr>
          </thead>
          <tbody>
            {BaysTable.filter((bay) => {
              // process BayNo range filter
              const _startbay =
                FilterStartBayNo === ""
                  ? Math.min(...BaysTable.map((bay) => parseInt(bay.BayNo)))
                  : FilterStartBayNo;

              const _endbay =
                FilterEndBayNo === ""
                  ? Math.max(...BaysTable.map((bay) => parseInt(bay.BayNo)))
                  : FilterEndBayNo;

              // process IncludeBay filter
              let showIncludeBay = true;

              switch (FilterIncludeBays) {
                case "COUNTED":
                  showIncludeBay = bay.Counted > 0;
                  break;
                case "NOTCOUNTED":
                  showIncludeBay = bay.Counted === 0;
                  break;
                default:
                  showIncludeBay = true;
                  break;
              }

              if (
                isInputInRange(parseInt(bay.BayNo), _startbay, _endbay) &&
                showIncludeBay &&
                (FilterStatus === "" || bay.Status === FilterStatus) &&
                (FilterCheck === "" || bay.Check === FilterCheck)
              ) {
                return true;
              }

              return false;
            }).map((bay, index) => (
              <tr key={index}>
                <td>{bay.BayNo}</td>
                <td>{bay.ItemQuantity}</td>
                <td>{bay.Counted}</td>
                <td>{bay.CountedStaff}</td>
                <td>{bay.Recounted}</td>
                <td>{bay.RecountStaff}</td>
                <td>
                  {bay.LastCountTime === null ? "" : ConvertDateFormat(bay.LastCountTime, "en-CA")}
                </td>
                <td>{bay.Status}</td>
                <td>{bay.Check}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
