import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const EditIssue = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");
  const [IsImageModified, setIsImageModified] = useState(false);

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstoreaudittoolimages"); // create a containerClient

  const emptyIssueDetails = {
    IssueID: 0,
    IssueSubject: "",
    IssueDescription: "",
    IssueImageURL: "",
    AssignedTo: "",
    Resolved: 0,
    ResolveComment: "",
    DateCreated: "",
    DateResolved: "",
    DueDate: "",
  };
  const [IssueDetails, setIssueDetails] = useState(emptyIssueDetails);
  const emptyIssueImage = {
    ImageLineNo: 0,
    ImageURL: "",
  };
  const [IssueImages, setIssueImages] = useState(emptyIssueImage);

  const emptyUploadedImageFile = {
    name: "",
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const refSubject = useRef();
  const refDescription = useRef();
  const refAssignedTo = useRef();
  const refResolveComment = useRef();
  const refDueDate = useRef();

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const getIssueDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: props.SelectedIssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIssueDetails({
          IssueID: spResponse.Table[0].ID,
          IssueSubject: spResponse.Table[0].IssueSubject,
          IssueDescription: spResponse.Table[0].IssueDescription,
          IssueImageURL: spResponse.Table[0].IssueImageURL,
          AssignedTo: spResponse.Table[0].AssignedTo,
          Resolved: spResponse.Table[0].Resolved,
          ResolveComment: spResponse.Table[0].ResolveComment,
          DateCreated: spResponse.Table[0]["Date Created"],
          DateResolved: spResponse.Table[0]["Date Resolved"],
          DueDate: ConvertDateFormat(spResponse.Table[0]["Due Date"], "en-CA"),
        });

        refSubject.current.value = spResponse.Table[0].IssueSubject;
        refDescription.current.value = spResponse.Table[0].IssueDescription;
        refAssignedTo.current.value = spResponse.Table[0].AssignedTo;
        refDueDate.current.value = ConvertDateFormat(spResponse.Table[0]["Due Date"], "en-CA");
        refResolveComment.current.value = spResponse.Table[0]["Resolved Comment"];
      } else {
        setIssueDetails(emptyIssueDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setIssueImages({
          ImageLineNo: spResponse.Table1[0].ImageLineNo,
          ImageURL: spResponse.Table1[0].ImageURL,
        });
      } else {
        setIssueImages(emptyIssueImage);
      }
    }
  };

  const handleClick_Resolve = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Resolve`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
          ResolveComment: refResolveComment.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  const handleClick_Save = async () => {
    // upload image if we wanted to
    if (IsImageModified) {
      if (UploadedImageFile.blob === "") {
        handleClick_DeleteImage();
      } else {
        const blobClient = containerClient.getBlockBlobClient(UploadedImageFile.name);

        trackPromise(blobClient.uploadData(UploadedImageFile.blob))
          .then(async () => {
            const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstoreaudittoolimages/${encodeURIComponent(
              UploadedImageFile.name
            )}`;

            const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Upload`, {
              method: "POST",
              body: new URLSearchParams({
                inputJSON: JSON.stringify({
                  IssueID: props.SelectedIssueID,
                  ImageURL: blobURL,
                }),
              }),
            });

            if (resObj.response === null) {
              setStoreAuditToolResponse(resObj.exception);
            } else {
              editIssue();
            }
          })
          .catch((err) => {
            setStoreAuditToolResponse(`ERROR!: Image upload error. ${err}`);
          });
      }
    } else {
      editIssue();
    }
  };

  const editIssue = async () => {
    // update
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Edit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: props.SelectedIssueID,
          IssueSubject: refSubject.current.value,
          IssueDescription: refDescription.current.value,
          AssignedTo: refAssignedTo.current.value,
          ResolveComment: refResolveComment.current.value,
          DueDate: refDueDate.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  const handleChange_FileUploader = async (uploadedFile) => {
    // we assume only one photo per issue for now
    if (["image/jpeg", "image/png", "image/webp"].includes(uploadedFile.type)) {
      await trackPromise(
        compressFile(uploadedFile).then(async (compressedFile) => {
          setUploadedImageFile({
            name: compressedFile.name,
            blob: compressedFile,
          });

          setIsImageModified(true);
        })
      );

      // let's assume only one photo per issue for now
      // if (IssueImages.length === 0) {
      // } else {
      // }
    } else {
      setUploadedImageFile(emptyUploadedImageFile);
      setStoreAuditToolResponse("ERROR!: File type not supported. (.png, .jpg, .jpeg, .webp)");
    }
  };

  const compressFile = (file) => {
    return new Promise(async function (resolve, reject) {
      let compressedFile = await imageCompression(file, {
        maxSizeMB: 2, // max image size = 200kb
        maxWidthOrHeight: 300,
        useWebWorker: true,
      });

      // let compressedFile = file;
      resolve(compressedFile);
    });
  };

  const handleClick_Delete = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  const handleClick_DeleteImage = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  useEffect(() => {
    getIssueDetails();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {props.Access.StoreAuditToolAdmin ? (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Subject:</strong>
              </p>
              <input
                type="text"
                ref={refSubject}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <textarea
                ref={refDescription}
                rows="10"
                placeholder="Enter issue description here..."
              ></textarea>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Assigned to:</strong>
              </p>
              <input
                type="text"
                ref={refAssignedTo}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Due:</strong>
              </p>
              <input
                type="date"
                ref={refDueDate}
              />
            </div>
          </>
        ) : (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Subject:</strong>
              </p>
              <p>{IssueDetails.IssueSubject}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <textarea
                ref={refDescription}
                rows="10"
                placeholder="Enter issue description here..."
                disabled
              ></textarea>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Assigned to:</strong>
              </p>
              <p>{IssueDetails.AssignedTo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Due:</strong>
              </p>
              <p>{ConvertDateFormat(IssueDetails.DueDate, "en-CA")}</p>
            </div>
          </>
        )}
        <div className="stocktake-create-input-row">
          <p>
            <strong>Resolve Comment:</strong>
          </p>
          <textarea
            ref={refResolveComment}
            rows="10"
            placeholder="Enter resolve comment here..."
          ></textarea>
        </div>
        {IssueDetails.IssueImageURL !== "" && (
          <>
            <p>
              <strong>Sample Image</strong>
            </p>
            <div className="blockedorders-option-row">
              <div className="storeaudittool-fileupload-container">
                <img
                  className="storeaudittool-image"
                  src={IssueDetails.IssueImageURL}
                />
              </div>
            </div>
          </>
        )}
        <p>
          <strong>Resolved Image</strong>
        </p>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {IssueDetails.Resolved === 0 ? (
              IsImageModified ? (
                UploadedImageFile.blob === "" ? (
                  <FileUploader
                    multiple={false}
                    handleChange={handleChange_FileUploader}
                    types={["png", "jpg", "jpeg", "webp"]}
                    children={
                      <MenuButton
                        className="no-highlight"
                        button="UP"
                        colour={green}
                        title="Upload Photo"
                      />
                    }
                  />
                ) : (
                  <>
                    <FileUploader
                      multiple={false}
                      handleChange={handleChange_FileUploader}
                      types={["png", "jpg", "jpeg", "webp"]}
                      children={
                        <img
                          className="storeaudittool-image btnHover"
                          src={URL.createObjectURL(UploadedImageFile.blob)}
                        />
                      }
                    />
                    <FontAwesomeIcon
                      className="storeaudittool-deleteimage-icon btnHover"
                      icon="fas fa-times"
                      onClick={() => {
                        setIsImageModified(true);
                        setUploadedImageFile(emptyUploadedImageFile);
                      }}
                    />
                  </>
                )
              ) : IssueImages.ImageLineNo === 0 ? (
                <FileUploader
                  multiple={false}
                  handleChange={handleChange_FileUploader}
                  types={["png", "jpg", "jpeg", "webp"]}
                  children={
                    <MenuButton
                      button="UP"
                      colour={green}
                      title="Upload Photo"
                    />
                  }
                />
              ) : (
                <>
                  <FileUploader
                    multiple={false}
                    handleChange={handleChange_FileUploader}
                    types={["png", "jpg", "jpeg", "webp"]}
                    children={
                      <img
                        className="storeaudittool-image btnHover"
                        src={IssueImages.ImageURL}
                      />
                    }
                  />
                  <FontAwesomeIcon
                    className="storeaudittool-deleteimage-icon btnHover"
                    icon="fas fa-times"
                    onClick={() => {
                      setIsImageModified(true);
                      setUploadedImageFile(emptyUploadedImageFile);
                    }}
                  />
                </>
              )
            ) : (
              <img
                className="storeaudittool-image"
                src={IssueImages.ImageURL}
              />
            )}
          </div>
        </div>

        <p className="error-message">{StoreAuditToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        {props.Access.StoreAuditToolAdmin && (
          <>
            <FooterButton
              button="Delete"
              colour={green}
              onClick={handleClick_Delete}
            />
            <FooterButton
              button="Rslve"
              colour={green}
              onClick={handleClick_Resolve}
            />
          </>
        )}
        <FooterButton
          button="Save"
          colour={green}
          onClick={handleClick_Save}
        />
      </div>
    </div>
  );
};
