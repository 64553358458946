import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupConfirmCheck = (props) => {
  const handleClose = () => {
    props.setShow(false);
  };

  const handleClick_Yes = async () => {
    await props.setRecountStatus("CONFIRMED");
    await props.useRecountForBay();
    props.setShow(false);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>This will overwrite bay to RECOUNT. Would you like to continue?</strong>
            </p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
