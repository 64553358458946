import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupAddSubCat } from "./PopupAddSubCat";

export const AdminBlockSubCat = (props) => {
  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [ddlDepartment, setDDLDepartment] = useState([]);
  const [ddlCategory, setDDLCategory] = useState([]);

  const emptyRow = {
    Department: "",
    Category: "",
    SubCategory: "",
  };
  const [selectedRow, setSelectedRow] = useState(emptyRow);
  const [SubCatTable, setSubCatTable] = useState([]);

  const [show, setShow] = useState(false);

  const [FilterDepartment, setFilterDepartment] = useState("ALL");
  const [FilterCategory, setFilterCategory] = useState("ALL");

  useEffect(() => {
    refreshTable(FilterDepartment, FilterCategory);
  }, [FilterDepartment, FilterCategory]);

  const refreshTable = async (_department, _category) => {
    setSelectedRow("");
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_BlockedSubCatList_Refresh`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Department: _department,
          Category: _category,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setSubCatTable(
          spResponse.Table.map((item) => ({
            Department: item.Department,
            Category: item.Category,
            SubCategory: item.SubCat,
          }))
        );
      } else {
        setSubCatTable([]);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setDDLDepartment(spResponse.Table1.map((item) => item.Department));
      } else {
        setDDLDepartment([]);
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setDDLCategory(spResponse.Table2.map((item) => item.Category));
      } else {
        setDDLCategory([]);
      }
    }
  };

  const handleClick_Back = () => {
    setStockTakeResponse("");
    props.setPage("admin-menu");
  };

  const resetOption = async () => {
    setSelectedRow(emptyRow);

    setFilterDepartment("ALL");
    setFilterCategory("ALL");

    await refreshTable("ALL", "ALL");
  };

  const handleClick_Delete = async () => {
    setStockTakeResponse("");

    if (JSON.stringify(selectedRow) === JSON.stringify(emptyRow)) {
      setStockTakeResponse("ERROR!: No SubCat selected.");
    }
    const resObj = await runFetch(`${spURL}Stocktake_BlockedSubCatList_Remove`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(selectedRow),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStockTakeResponse(spResponse.Table[0].OutputString);
        await resetOption();
      }
    }
  };

  const handleClick_Add = () => {
    setShow(true);
  };

  return (
    <div className="stocktake-admin-blocksubcat-page-container">
      <div className="stocktake-admin-blocksubcat-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Department:</strong>
          </p>
          <select
            id="stocktake-admin-blocksubcat-department"
            onChange={(e) => setFilterDepartment(e.target.value)}
          >
            {ddlDepartment.map((item, index) => (
              <option
                key={index}
                value={item}
                name={item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Category:</strong>
          </p>
          <select
            id="stocktake-admin-blocksubcat-category"
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            {ddlCategory.map((item, index) => (
              <option
                key={index}
                value={item}
                name={item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Department</th>
              <th>Category</th>
              <th>SubCat</th>
            </tr>
          </thead>
          <tbody>
            {SubCatTable.map((item, index) => (
              <tr
                key={index}
                className={`btnHover ${
                  JSON.stringify(item) === JSON.stringify(selectedRow) && "background-lightblue"
                }`}
                onClick={() => {
                  setSelectedRow(
                    JSON.stringify(item) === JSON.stringify(selectedRow) ? emptyRow : item
                  );
                }}
              >
                <td>{item.Department}</td>
                <td>{item.Category}</td>
                <td>{item.SubCategory}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <div className="blockedorders-option-row">
          <MenuButton
            title="Add"
            colour={red}
            button="A"
            onClick={handleClick_Add}
          />
          <MenuButton
            title="Delete"
            colour={red}
            button="D"
            onClick={handleClick_Delete}
          />
        </div>
        <br />
        <p className="error-message">{StockTakeResponse}</p>

        <PopupAddSubCat
          show={show}
          setShow={setShow}
          refreshTable={refreshTable}
          setStockTakeResponse={setStockTakeResponse}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
