import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupErrorList } from "./PopupErrorList";
import { runFetch } from "../../functions/RunFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const POSStatusMain = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);

  const [POSStatusTable, setPOSStatusTable] = useState([]);

  const [SelectedStoreName, setSelectedStoreName] = useState("");
  const [showErrorList, setShowErrorList] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}POS_Ping_Status`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setPOSStatusTable(
          spResponse.Table.map((item) => ({
            State: item.State,
            NickName: item.NickName,
            StoreName: item.StoreName,
            POS1DataSyncColour: item["POS1Sync Colour"],
            POS2DataSyncColour: item["POS2Sync Colour"],
            POS1Investigate: item.POS1Investigate,
            POS2Investigate: item.POS2Investigate,
            ErrorCount: item.ErrorCount,
          }))
        );
      } else {
        setPOSStatusTable([]);
      }
    }
  };

  const handleClick_ErrorDetails = (_storename) => {
    setMenuResponse("");
    setSelectedStoreName(_storename);
    setShowErrorList(true);
  };

  const handleClick_ToggleInvestigate = async (_storename, _pos) => {
    const resObj = await runFetch(`${spURL}Handheld_POSStatus_ToggleInvestigate`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreName: _storename,
          POS: _pos,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        // setMenuResponse(spResponse.Table[0].OutputString);
        await pageLoad();
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p className="error-message">{MenuResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>State</th>
              <th>Store</th>
              <th>POS 1</th>
              <th>POS 2</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {POSStatusTable.map((item, index) => (
              <tr
                key={index}
                className="btnHover"
                storename={item.StoreName}
              >
                <td onClick={() => handleClick_ErrorDetails(item.StoreName)}>{item.State}</td>
                <td onClick={() => handleClick_ErrorDetails(item.StoreName)}>{item.StoreName}</td>
                <td
                  style={{ backgroundColor: item.POS1DataSyncColour }}
                  onClick={() => handleClick_ToggleInvestigate(item.StoreName, 1)}
                >
                  {item.POS1Investigate === 1 && (
                    <FontAwesomeIcon icon={solid("magnifying-glass")} />
                  )}
                </td>
                <td
                  style={{ backgroundColor: item.POS2DataSyncColour }}
                  onClick={() => handleClick_ToggleInvestigate(item.StoreName, 2)}
                >
                  {item.POS2Investigate === 1 && (
                    <FontAwesomeIcon icon={solid("magnifying-glass")} />
                  )}
                </td>
                <td onClick={() => handleClick_ErrorDetails(item.StoreName)}>{item.ErrorCount}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PopupErrorList
          show={showErrorList}
          setShow={setShowErrorList}
          StoreName={SelectedStoreName}
          pageLoad={pageLoad}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          onClick={() => props.setPage("menu")}
          colour={blue}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
