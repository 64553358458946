import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const PopupInvoiceImage = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");
  const [ImageJPGBase64, setImageJPGBase64] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    setImageJPGBase64("");
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}TiffToPngBase64`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: props.InvoiceDetails.InvoiceImageTiffBase64,
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      setImageJPGBase64(resObj.response);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <img
              style={{ width: "100%" }}
              src={`data:image/png;base64, ${ImageJPGBase64}`}
            />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
