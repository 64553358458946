import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";

export const PopupQuantityScan = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const [Item, setItem] = useState({
    ItemNo: "",
    ImageURL: "",
    Description: "",
    ReorderNumber: "",
    Supplier: "",
  });

  const refQty = useRef();

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}StockTake_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Barcode: props.Barcode,
          StockTakeID: props.StockTake,
          BayNo: props.BayNo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setStockTakeResponse(resObj.exception);
      props.play();
      props.setShow(false);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        // load item details
        setItem((values) => ({
          ...values,
          ItemNo: spResponse.Table[0].ItemNo,
          ImageURL: spResponse.Table[0].ImageURL,
          Description: spResponse.Table[0].Description,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          Supplier: spResponse.Table[0].Supplier,
        }));
      } else {
        props.setStockTakeResponse("ERROR!: Item not found.");
        props.play();
        props.setShow(false);
      }
    }
  };

  const handleClick_Confirm = async () => {
    setConfirmResponse("");
    props.setStockTakeResponse("");
    const resObj = await runFetch(`${spURL}Stocktake_Add_Count`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: Item.ItemNo,
          Quantity: refQty.current.value,
          StockTake: props.StockTake,
          UserID: userid,
          BayNo: props.BayNo,
          Store: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
      props.play();
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setStockTakeResponse(spResponse.Table[0].OutputString);
          if (!spResponse.Table[0].OutputString.includes("OK!:")) {
            props.play();
          }
          props.handleClick_Toggle();
          props.clearBarcode();
          props.setShow(false);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <img
              src={Item.ImageURL}
              alt="Item"
              width="100"
              height="100"
            />
            <div className="stocktake-create-input-row">
              <p>
                <strong>Desc:</strong>
              </p>
              <p>{Item.Description}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Reorder:</strong>
              </p>
              <p>{Item.ReorderNumber}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Supplier:</strong>
              </p>
              <p>{Item.Supplier}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Qty:</strong>
              </p>
              <input
                type="text"
                id="stocktake-scan-quantityscan-qty"
                ref={refQty}
                autoFocus
              />
            </div>
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Confirm}
            >
              <strong>Confirm</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
