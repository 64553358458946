import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, orange } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { CapitaliseString } from "../../functions/CapitaliseString";
import Modal from "react-bootstrap/Modal";
import { PopupInvoiceImage } from "./PopupInvoiceImage";
import { PopupInvoiceComments } from "./PopupInvoiceComments";

export const PopupInvoiceDetails = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [NonStockInvoiceResponse, setNonStockInvoiceResponse] = useState("");

  const emptyInvoiceDetails = {
    InvoiceID: 0,
    InvoiceNo: "",
    NonStockSupplierID: 0,
    NonStockSupplierName: "",
    Amount: 0,
    QuoteID: 0,
    DateInvoiced: "",
    DateCreated: "",
    DateExported: "",
    Comments: "",
    StoreNo: "",
    InvoiceImageTiffBase64: "",
  };
  const [InvoiceDetails, setInvoiceDetails] = useState(emptyInvoiceDetails);

  const [showInvoiceImage, setShowInvoiceImage] = useState(false);
  const [showInvoiceComments, setShowInvoiceComments] = useState(false);

  const refComments = useRef();

  const cleanPopup = () => {
    setInvoiceDetails(emptyInvoiceDetails);
    setNonStockInvoiceResponse("");
    setShowInvoiceImage(false);
  };

  const handleClose = () => {
    cleanPopup();
    props.setShow(false);
  };

  const getInvoiceDetails = async (_invoiceid) => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Invoice_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          InvoiceID: _invoiceid,
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setInvoiceDetails({
          InvoiceID: spResponse.Table[0].InvoiceID,
          InvoiceNo: spResponse.Table[0].InvoiceNo,
          NonStockSupplierID: spResponse.Table[0].NonStockSupplierID,
          NonStockSupplierName: spResponse.Table[0].NonStockSupplierName,
          Amount: spResponse.Table[0].Amount,
          QuoteID: spResponse.Table[0].QuoteID,
          DateInvoiced: spResponse.Table[0]["Date Invoiced"],
          DateApproved:
            spResponse.Table[0]["Date Approved"] === null
              ? ""
              : spResponse.Table[0]["Date Approved"],
          DateExported:
            spResponse.Table[0]["Date Exported"] === null
              ? ""
              : spResponse.Table[0]["Date Exported"],
          Comments: spResponse.Table[0].Comments,
          StoreNo: spResponse.Table[0].StoreNo,
          InvoiceImageTiffBase64: spResponse.Table[0].InvoiceImageTiffBase64,
        });

        refComments.current.value = spResponse.Table[0].Comments;
      } else {
        setInvoiceDetails(emptyInvoiceDetails);
      }
    }
  };

  const handleShow = () => {
    setNonStockInvoiceResponse("");
    getInvoiceDetails(props.SelectedInvoiceID);
  };

  const handleClick_ToggleApprove = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Invoice_ToggleApprove`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          InvoiceID: props.SelectedInvoiceID,
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setNonStockInvoiceResponse(spResponse.Table[0].OutputString);
        cleanPopup();
        props.setShow(false);
        props.getInvoices();
      }
    }
  };

  const handleClick_ViewInvoiceImage = () => {
    setNonStockInvoiceResponse("");
    setShowInvoiceImage(true);
  };

  const handleClick_Comment = () => {
    setNonStockInvoiceResponse("");
    setShowInvoiceComments(true);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="page-container">
            <div className="handheld-body-container">
              <h4>
                <strong>Invoice Details</strong>
              </h4>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Store:</strong>
                </p>
                <p>{storename}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>InvoiceNo:</strong>
                </p>
                <p>{InvoiceDetails.InvoiceNo}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Supplier:</strong>
                </p>
                <p>{InvoiceDetails.NonStockSupplierName}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Amount:</strong>
                </p>
                <p>{convertToCurrency(InvoiceDetails.Amount, "$")}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Invoice Date:</strong>
                </p>
                <p>{ConvertDateFormat(InvoiceDetails.DateInvoiced, "en-CA")}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Comment:</strong>
                </p>
                <textarea
                  rows={5}
                  className="disabled"
                  ref={refComments}
                ></textarea>
              </div>
              {InvoiceDetails.DateApproved !== "" && (
                <div className="stocktake-create-input-row">
                  <p>
                    <strong>Approved on:</strong>
                  </p>
                  <p>{ConvertDateFormat(InvoiceDetails.DateApproved, "en-CA")}</p>
                </div>
              )}
              <br />
              <p className="error-message">{NonStockInvoiceResponse}</p>
            </div>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_ViewInvoiceImage}
            >
              <strong>View</strong>
            </button>
            {InvoiceDetails.DateExported === "" && (
              <button
                className="popup-button"
                onClick={handleClick_Comment}
              >
                <strong>Comment</strong>
              </button>
            )}
            {InvoiceDetails.DateExported === "" && (
              <button
                className="popup-button"
                onClick={handleClick_ToggleApprove}
              >
                <strong>{InvoiceDetails.DateApproved === "" ? "Approve" : "Unapprove"}</strong>
              </button>
            )}
          </div>

          <PopupInvoiceImage
            show={showInvoiceImage}
            setShow={setShowInvoiceImage}
            InvoiceDetails={InvoiceDetails}
          />

          <PopupInvoiceComments
            show={showInvoiceComments}
            setShow={setShowInvoiceComments}
            SelectedInvoiceID={props.SelectedInvoiceID}
            InvoiceDetails={InvoiceDetails}
            setNonStockInvoiceResponse={setNonStockInvoiceResponse}
            getInvoiceDetails={getInvoiceDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
