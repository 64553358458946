import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const PopupInvoiceComments = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refComments = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");

    refComments.current.value = props.InvoiceDetails.Comments;
  };

  const handleClick_Ok = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}Handheld_NonStock_Invoice_Edit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          InvoiceID: props.SelectedInvoiceID,
          Comments: refComments.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setNonStockInvoiceResponse(spResponse.Table[0].OutputString);
        await props.getInvoiceDetails(props.SelectedInvoiceID);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Comments:</strong>
              </p>
              <input
                type="text"
                ref={refComments}
                className={props.Type === "VIEW" && "disabled"}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Ok}
            >
              <strong>Ok</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
