import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Reports = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [IncludeReports, setIncludeReports] = useState([
    "StockTakeReport",
    "BaySummary",
    "ItemSummary",
    "BayDetails",
    "BaysNotCounted",
    "Check",
  ]);

  useEffect(() => {
    setStockTakeResponse("");
  }, []);

  const handleCheck_IncludeReports = (e) => {
    const _checked = e.target.checked;
    const _report = e.target.value;

    if (_checked) {
      setIncludeReports([...IncludeReports, _report]);
    } else {
      setIncludeReports(IncludeReports.filter((item) => item !== _report));
    }
  };

  const handleClick = async () => {
    setStockTakeResponse("Please wait while the report is being loading...");

    const resObj = await runFetch(`${spURL}Stocktake_Export_Report`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          StockTake: props.CurrentStockTake,
          IncludeReports: IncludeReports.toString(),
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table[0].OutputString.substring(0, 6) === "ERROR!") {
        setStockTakeResponse(spResponse.Table[0].OutputString);
      } else {
        const blob = new Blob([spResponse.Table[0].OutputString], {
          type: "application/ms-excel",
        });

        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        a.href = url;
        a.download = `Stocktake_${props.CurrentStockTake}.xls`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setStockTakeResponse("Report is successfully downloaded.");
      }
    }
  };

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
          <hr />
          <h5>
            <strong>Select reports:</strong>
          </h5>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>StockTake Report</strong>
            </p>
            <input
              type="checkbox"
              value="StockTakeReport"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>Bay Summary</strong>
            </p>
            <input
              type="checkbox"
              value="BaySummary"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>Item Summary</strong>
            </p>
            <input
              type="checkbox"
              value="ItemSummary"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>Bay Details</strong>
            </p>
            <input
              type="checkbox"
              value="BayDetails"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>Bays Not Counted</strong>
            </p>
            <input
              type="checkbox"
              value="BaysNotCounted"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <div className="stocktake-reports-input-row">
            <p>
              <strong>Check</strong>
            </p>
            <input
              type="checkbox"
              value="Check"
              onClick={handleCheck_IncludeReports}
              defaultChecked
            />
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            Download
          </button>
        </div>

        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
