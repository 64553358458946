import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, blue } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const PopupTransactionReceipt = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const [ReceiptPreviewString, setReceiptPreviewString] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    setReceiptPreviewString("");
    props.setSelectedReceiptNo("");
    props.setShow(false);
  };

  const getReceiptPreviewString = async () => {
    const resObj = await runFetch(`${spURL}Handheld_PrintReceipt_Transaction_Receipt_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ReceiptNo: props.SelectedReceiptNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setReceiptPreviewString(spResponse.Table[0].ReceiptString);
      } else {
        setReceiptPreviewString("");
      }
    }
  };

  const handleShow = () => {
    getReceiptPreviewString();
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p className="error-message">{PopupResponse}</p>

            <div className="receipt-preview-container">
              <div className="receipt-preview">
                <pre>{ReceiptPreviewString}</pre>
              </div>
            </div>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
