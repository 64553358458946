import { useState } from "react";
import { Link } from "react-router-dom";

import { checkAccess, red } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import store from "../../store";

export const AdminMenu = (props) => {
  const [userid, setUserID] = store.useState("userid");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");
  checkAccess("StockTakeAdmin", userid, setIsSignedIn);

  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("admin-finalise")}
            button="FS"
            colour={red}
            title="Finalise Stocktake"
          />
          <MenuButton
            onClick={() => props.setPage("admin-blocksubcat")}
            button="BS"
            colour={red}
            title="Block SubCat"
          />
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
